import { useCallback, useState } from 'react';
import {
  CursorCache,
  PaginatedResults,
  PaginationInput,
  PaginationMetadata,
} from 'common/utils/types';
import { QueryStatus, useQuery, useQueryClient } from 'react-query';

/**
 * All the handlers the general pagination hook will return.
 */
export interface UsePaginatedQueryResultHandlers {
  nextPage: () => void;
  goBackPage: () => void;
  changePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  reset: () => void;
  invalidate: () => void;
}

/**
 * The return type of the general pagination hook.
 */
export interface UsePaginatedQueryResult<T>
  extends PaginatedResults<T>,
    PaginationMetadata,
    UsePaginatedQueryResultHandlers {
  isLoading: boolean;
  isError: boolean;
  status: QueryStatus;
  error: any;
}

/**
 * Extra config for the queries
 */
export interface PaginatedQueryOptions {
  queryOptions?: any;
  startRowsPerPage?: number;
  queryFnArgs?: any;
  url?: string;
}

// note: MUI paginated table uses 0-based index for page
// changing this lead to some funky behavior
export const FIRST_PAGE = 0;

const DEFAULT_CURSOR_CACHE = {
  [FIRST_PAGE]: null,
};

const DEFAULT_PAGINATION_METADATA: PaginationMetadata = {
  page: FIRST_PAGE,
  rowsPerPage: 100,
};

const DEFAULT_PAGINATED_RESULTS: PaginatedResults<any> = {
  cursor: '',
  hasMore: false,
  rows: [],
  totalRows: 0,
};

function usePaginatedQuery<T>(
  queryName: string,
  queryFn: (arg0: PaginationInput) => Promise<PaginatedResults<T>>,
  {
    queryFnArgs = {},
    queryOptions = {},
    startRowsPerPage = DEFAULT_PAGINATION_METADATA.rowsPerPage,
  }: PaginatedQueryOptions = {}
): UsePaginatedQueryResult<T> {
  const queryClient = useQueryClient();

  // ****************************************
  // State
  // ****************************************
  const [metadata, setMetadata] = useState<PaginationMetadata>({
    ...DEFAULT_PAGINATION_METADATA,
    rowsPerPage: startRowsPerPage,
  });
  const [cursorCache, setCursorCache] = useState<CursorCache>(DEFAULT_CURSOR_CACHE);
  const { rowsPerPage, page } = metadata;

  // ****************************************
  // Query
  // ****************************************
  const { isLoading, isError, status, error, data: result } = useQuery(
    [queryName, rowsPerPage, page, queryFnArgs],
    ({ queryKey }) =>
      queryFn({
        limit: rowsPerPage,
        cursor: cursorCache[queryKey[2] as number],
        ...queryFnArgs,
      }),
    {
      onSuccess: data => {
        setCursorCache(old => ({ ...old, [page + 1]: data.cursor }));
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );

  // ****************************************
  // Handlers
  // ****************************************
  const changePage = useCallback(
    (_, newPage: number) => {
      setMetadata(old => ({ ...old, page: newPage }));
    },
    [setMetadata]
  );

  const nextPage = useCallback(async () => {
    setMetadata(old => ({ ...old, page: old.page + 1 }));
  }, [setMetadata]);

  const goBackPage = useCallback(async () => {
    setMetadata(old => ({ ...old, page: Math.max(old.page - 1, FIRST_PAGE) }));
  }, [setMetadata]);

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(queryName);
  }, [queryClient, queryName]);

  const reset = useCallback(
    (metadataOverrides = {}) => {
      setMetadata({
        ...DEFAULT_PAGINATION_METADATA,
        ...metadataOverrides,
      });
      setCursorCache(DEFAULT_CURSOR_CACHE);
      invalidate();
    },
    [setMetadata, setCursorCache, invalidate]
  );

  const changeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const rowsPerPage = parseInt(event.target.value, 100);
      reset({ rowsPerPage });
    },
    [reset]
  );

  console.log('>>>>', result);

  return {
    ...metadata,
    ...(result || DEFAULT_PAGINATED_RESULTS),
    changePage,
    nextPage,
    goBackPage,
    invalidate,
    reset,
    changeRowsPerPage,
    isLoading,
    isError,
    status,
    error,
  };
}

export default usePaginatedQuery;
